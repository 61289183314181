@import '../variable';

body {
    background-color: white
}

#main {
    margin-top: 70px;
    padding-top: 24px;
    min-height: calc(100vh - 130px);

    &.register {
        
        .register__logo {
                height: 64px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 50px;
        }

        .register__illust {
            width: 100%;
            margin-bottom: 24px;
        }

        form {
            input[type="submit"] {
                margin-top: 50px;
            }

            .other-btn {
                display: block;
                text-align: center;
                margin-top: 20px;
            }
        }

        .removeAvatar {
            width: 32px;
            height: 32px;
            display: inline-block;
            border-radius: 50%;
            background-color: $color-primary;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 24px;
                position: relative;
            top: -1px;
            top: -1px;
            }
        }

        .birthdate {
            margin-left: -9px;
            margin-right: -9px;

            > div {
                padding-left: 9px;
                padding-right: 9px;
            }
        }

        .main-card {
            padding: 20px 15px 15px;
            border-radius: 15px;
            background-color: $color-base-light;

            h1 {
                font-family: $fontGlacial;
                margin-bottom: 15px;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
            }

            .scrollable {
                margin-bottom: 20px;
                max-height: calc(100vh - 376px);
                overflow-y: auto;

                article {
                    font-size: 14px;

                    ol {
                        padding-left: 15px;

                        li {
                            padding-left: 10px;
                        }
                    }

                    p {
                        margin-bottom: 10px;
                    }
                }
            }

            &__btn {
                display: flex;
                justify-content: center;

                > * {
                    margin: 0 15px;
                }
            }
        }

        // Dashboard
        .card-overview {
            list-style: none;
            padding: 0;
            margin-left: -12px;
            margin-right: -12px;
            margin-top: 32px;

            li {
                padding-left: 12px;
                padding-right: 12px;
                margin-bottom: 24px;

                .wrapper {
                    // padding: 12px 16px;
                    border-radius: 4px;
                    text-decoration: none;
                    box-shadow: $shadow;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 120px;
                    background-color: #F8D8DE;
                    // border-bottom: 4px solid $color-primary;
                    background-image: url(../../img/misc/bg-pattern.png);                

                    h3 {
                        font-weight: bold;
                        margin-top: 10px;
                    }

                    p {
                        font-size: 14px;
                    }
                }

//                 &:nth-child(1) {
// 
//                     .wrapper {
//                         background-color: $color-info;
//                         color:white;
//                     }
//                 }
//                 &:nth-child(2) {
// 
//                     .wrapper {
//                         background-color: $color-success;
//                         color:white;
//                     }
//                 }
//                 &:nth-child(3) {
// 
//                     .wrapper {
//                         background-color: $color-warning;
//                         color:white;
//                     }
//                 }
//                 &:nth-child(4) {
// 
//                     .wrapper {
//                         background-color: $color-error;
//                         color:white;
//                     }
//                 }
            }
        }

        .card-preview {
            // padding: 12px 16px;
            // border-radius: 4px;
            // color: $color-base;
            // text-decoration: none;
            // box-shadow: $shadow;
            border-bottom: 1px solid mix($color-base, white, 40%);

            ._title {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 14px;
            }

            // ._content,
            // ._content p {
            //     font-size: 16px !important;
            // }
        }

        .quiz-preview {

            .add-q {
                margin-bottom: 40px;
            }

            .edit-q {
                margin-top: 24px;
            }

            .quiz-section {
                padding: 24px 0;
                border-top: 1px solid mix($color-base, white, 40%);

                ._q {
                    margin-bottom: 10px;
                }

                ._a {
                    padding-left: 10px;

                    &.is-correct {
                        background-color: $color-success;
                        color: white;
                        font-weight: bold;
                        border-radius: 20px;
                        display: inline-block;
                        padding: 1px 9px 2px;
                    }
                }
            }
        }
    }
}